import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import Fade from "react-reveal/Fade"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBed } from "@fortawesome/free-solid-svg-icons"
import ApartmentItem from "./apartment-item"

import irieCover from "../images/IrieVibesApt/1.jpg"
import Retreat2Cover from "../images/OceanViewII/1.jpg"
import Retreat3Cover from "../images/OceanViewIII/1.jpg"

import retreatII1 from "../images/OceanViewII/1.jpg"
import retreatII2 from "../images/OceanViewII/2.jpg"
import retreatII3 from "../images/OceanViewII/3.jpg"

import retreatIII1 from "../images/OceanViewIII/1.jpg"
import retreatIII2 from "../images/OceanViewIII/2.jpg"
import retreatIII3 from "../images/OceanViewIII/3.jpg"
// import retreatIII4 from '../images/OceanViewIII/4.JPG'
import retreatIII5 from "../images/OceanViewIII/5.jpg"
import retreatIII6 from "../images/OceanViewIII/6.jpg"
import retreatIII7 from "../images/OceanViewIII/7.jpg"
import retreatIII8 from "../images/OceanViewIII/8.jpg"
import retreatIII9 from "../images/OceanViewIII/9.jpg"

import irie1 from "../images/IrieVibesApt/1.jpg"
import irie2 from "../images/IrieVibesApt/2.jpg"
import irie3 from "../images/IrieVibesApt/3.jpg"
import irie4 from "../images/IrieVibesApt/4.jpg"
import irie5 from "../images/IrieVibesApt/5.jpg"
import irie6 from "../images/IrieVibesApt/6.jpg"
import irie7 from "../images/IrieVibesApt/7.jpg"

const openUp = keyframes`
    from {
        width: 0%;
    }

    to {
        width: 50%;
    }
`

const Container = styled.div`
  padding: 5%;
`
const Title = styled.h1`
  font-size: 30px;
`
const ApartmentContainer = styled.div`
  margin-bottom: 50px;
  border: 0.5px solid #f1f1f1;
  border-radius: 10px;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.1);
  padding: 25px;

  /* ::after {
        content: '';
        background-color: #f8a02f;
        width: 30%;
        display: block;
        margin: 0 auto;
        height: 4px;


    } */
`
const AptTitle = styled.p`
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.9;
  display: inline-block;
  margin: 0 0 20px 10px;
  font-family: "Open Sans";
`
const AptListing = styled.p`
  line-height: 200%;
  font-size: 16px;
  font-family: "Open Sans";
`
const AptPricing = styled.p`
  font-weight: 600;
  color: #f8a02f;
  font-size: 23px;
  font-family: "Open Sans";
`

const BookApt = styled.a`
  background-color: #ffbe6e;
  color: white;
  padding: 10px 15px;
  font-family: "Open Sans";
  font-weight: 900;
  border: none;
  margin: 5px;
  transition: 0.5s ease;
  font-size: 15px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 1px 3px 5px #ffbe6e52;
  display: block;
  text-align: center;

  :hover {
    background-color: #ffc987;
  }

  :focus {
    background-color: #ffc987;
    outline: none;
  }
`

const ListingsContainer = styled.div`
  border-bottom: 1px solid #fafafa;
  font-family: "Quicksand";
  white-space: nowrap;
  overflow: scroll;
  max-width: 900px;
  margin: 0 auto;
`
const List = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;

  @media (min-width: 600px) {
    justify-content: center;
  }
`
const ListItem = styled.div`
    margin-right: 25px;
    margin-bottom: 5px;
    display:flex;
    align-items: center;
    font-weight: ${props => (props.selected ? 500 : 400)};
    cursor: pointer;

    border-radius: 4px;
    /* border: ${props =>
      props.selected ? "1px solid rgba(0,0,0,0.14)" : "none"}; */
    /* padding: 10px 15px; */

    ::after {
        content: '';
        display: ${props => (props.selected ? "block" : "none")};
        height: 2px;
        background-color: #F8A02F;
        width: 50%;
        margin: 4px auto;
        animation: ${openUp} 0.2s ease-in-out forwards;
    }

    :hover {
        color: #000000;
        
        ::after {
            content: '';
            display: block;
            height: 2px;
            background-color: #F8A02F;
            /* background-color: black; */
            width: 50%;
            margin: 4px auto;
            animation: ${openUp} 0.2s ease-in-out forwards;

        }
    }
`

class Apartments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: "apt2",
      listing: listings[2],
    }
  }

  selectApartment = id => {
    this.setState({ listing: listings[id], selected: `apt${id}` })
  }

  render() {
    const { listing } = this.state
    return (
      <Container id="listings">
        {/* <FontAwesomeIcon style={{margin: '15px auto', display: 'block'}} size={'1x'} color="#f8a02f" icon={faBed} /> */}
        <ListingsContainer>
          <Fade right>
            <List>
              <ListItem
                onClick={() => this.selectApartment(2)}
                selected={this.state.selected === "apt2"}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 10px", display: "inline-block" }}
                  size={"1x"}
                  color="#f8a02f"
                  icon={faBed}
                />{" "}
                Irie Vibes
              </ListItem>
              <ListItem
                onClick={() => this.selectApartment(0)}
                selected={this.state.selected === "apt0"}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 10px", display: "inline-block" }}
                  size={"1x"}
                  color="#f8a02f"
                  icon={faBed}
                />{" "}
                Ocean View Retreat II
              </ListItem>
              <ListItem
                onClick={() => this.selectApartment(1)}
                selected={this.state.selected === "apt1"}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 10px", display: "inline-block" }}
                  size={"1x"}
                  color="#f8a02f"
                  icon={faBed}
                />{" "}
                Ocean View Retreat III
              </ListItem>
            </List>
          </Fade>
        </ListingsContainer>
        <ApartmentItem
          key={listing.title}
          photos={listing.photos}
          title={listing.title}
          listing={listing.listing}
          pricing={listing.pricing}
          cover={listing.cover}
          irie={listing.irie}
          
        />
        {/* {
                    listings.map(listing=>(
                        <ApartmentItem title={listing.title} listing={listing.listing} pricing={listing.pricing} cover={listing.cover} />
                    ))
                } */}
        {/* <BookApt href="mailto:oceanechostables@hotmail.com?subject=Booking Request for an Adventure Stay&body=Hi there, I'm interested in making a booking for an Adventure Stay Apartment!">Book an Apartment</BookApt> */}
      </Container>
    )
  }
}

class ApartmentListing extends React.PureComponent {
  render() {
    return (
      <ApartmentContainer>
        <AptTitle>{this.props.title}</AptTitle>
        <AptListing>{this.props.listing}</AptListing>
        <AptPricing>{this.props.pricing}</AptPricing>
      </ApartmentContainer>
    )
  }
}

const listings = [
  {
    title: "Ocean View Retreat II",
    listing:
      "The downstairs apartment is a large, airy open planned apartment with a well equipped kitchen/living area and a spacious patio leading out to the lawn, gardens and yoga shala. There are 2 comfortable bedrooms, one with a double bed and the other has 2 single beds ideal for families.",
    pricing: "$USD75/night",
    cover: Retreat2Cover,
    photos: [
      {
        src: retreatII1,
        width: 2592,
        height: 1936,
      },
      {
        src: retreatII2,
        width: 2592,
        height: 1936,
      },
      {
        src: retreatII3,
        width: 2592,
        height: 1936,
      },
    ],
  },
  {
    title: "Ocean View Retreat III",
    listing:
      "This upstairs apartment is tastefully and comfortably finished and boasts high ceilings and amazing 360 degree views. Situated on a cliff, it is private and tranquil with the sound of waves crashing and a cooling breeze. The modern kitchen and 2 queen bedrooms have patios as well as a large ocean facing patio off the spacious living area.",
    pricing: "USD$95/night",
    cover: Retreat3Cover,
    photos: [
      {
        src: retreatIII1,
        width: 640,
        height: 556,
      },
      {
        src: retreatIII2,
        width: 1936,
        height: 2592,
      },
      {
        src: retreatIII3,
        width: 639,
        height: 426,
      },
      {
        src: retreatIII5,
        width: 640,
        height: 478,
      },
      {
        src: retreatIII6,
        width: 768,
        height: 1024,
      },
      {
        src: retreatIII7,
        width: 768,
        height: 1024,
      },
      {
        src: retreatIII8,
        width: 640,
        height: 478,
      },
      {
        src: retreatIII9,
        width: 1024,
        height: 325,
      },
    ],
  },
  {
    title: "Irie Vibes",
    listing:
      "Our breezy and comfortable self catering apartment at Ocean Echo Stables is suitable for 2 persons and offers beautiful Atlantic Ocean views in a relaxing and rustic setting. There are two small patios to enjoy the eastern sunrises and starry night skies. This is the place for those who wish to kick back in a simple and natural setting after exploring the island for the day, far away from the hustle and bustle.",
    pricing: "$USD55/night",
    cover: irieCover,
    irie: true,
    photos: [
      {
        src: irie1,
        width: 640,
        height: 480,
      },
      {
        src: irie2,
        width: 640,
        height: 480,
      },
      {
        src: irie3,
        width: 640,
        height: 480,
      },
      {
        src: irie4,
        width: 480,
        height: 640,
      },
      {
        src: irie5,
        width: 640,
        height: 480,
      },
      {
        src: irie6,
        width: 640,
        height: 480,
      },
      {
        src: irie7,
        width: 640,
        height: 480,
      },
    ],
  },
]

export default Apartments

import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer";
import Navbar from "../components/navbar";
import Intro from "../components/intro";
import Rides from "../components/rides";
import About from "../components/about";
import Hikes from "../components/hikes";
import GalleryComp from "../components/gallery";
import GoogleMap from "../components/google-map";
import AdventureStay from "../components/adventure-stay";
import Apartments from "../components/apartments";
import Yoga from "../components/yoga";
import Contact from "../components/contact";
import MobileNavigation from "../components/mobile-navigation";
import Events from "../components/events";
import MainCarousel from "../components/carousel";

const IndexPage = () => (
  <Layout>
    <SEO title="Barbados Horse Riding" keywords={[`barbados`, `horse`, `riding`, `island`,`tours`, `ocean`,`echo`,`stables`]} />
    <Navbar />
    <MobileNavigation />
    <MainCarousel />
    <Intro />
    <Rides />
    <Yoga />
    <Events />
    <Hikes />
    <AdventureStay />
    <Apartments />
    <GalleryComp />
    <Contact />
    <About />
    <GoogleMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3884.4954493329706!2d-59.49247068504466!3d13.194181213524645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c43ed5ed8fe88bd%3A0xa981fe05c5ffee72!2sOcean+Echo+Stables!5e0!3m2!1sen!2stt!4v1553269780265" />
    <Footer />
  </Layout>
)

export default IndexPage

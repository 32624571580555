import React, { Component } from "react"
import AliceCarousel from "react-alice-carousel"
import "react-alice-carousel/lib/alice-carousel.css"
// import { Carousel } from 'react-responsive-carousel';
// import './carousel.css'
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components"

import cover1 from "../images/1-min.jpg"
import cover2 from "../images/2-min.jpg"
import cover3 from "../images/3-min.jpg"

const SlideContainer = styled.div`
  width: 100%;
  max-height: 650px;
  min-height: 290px;
`
const SlideInfo = styled.div`
  position: absolute;
  left: 0;
  right: 0%;
  bottom: 20%;
  max-width: 750px;
  padding: 0 20px;
  margin: 0 auto;
  text-align: center;

  @media (min-width: 375px) {
    bottom: 15%;
  }

  @media (min-width: 500px) {
    bottom: 10%;
  }

  @media (min-width: 625px) {
    font-size: 45px;
  }

  @media (min-width: 900px) {
    bottom: 5%;
  }
`
const SlideCallout = styled.h1`
  color: white;
  font-size: 23px;
  /* justify-self: flex-end; */
  /* align-self: flex-end; */
  /* position: relative; */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
  text-shadow: 0 0 25px rgba(0, 0, 0, 0.45);
  font-family: "PT Serif";

  @media (min-width: 375px) {
    font-size: 25px;
  }

  @media (min-width: 500px) {
    font-size: 35px;
  }

  @media (min-width: 625px) {
    font-size: 45px;
  }

  @media (min-width: 900px) {
    font-size: 60px;
  }
`

const Container = styled.div`
  padding: 0 0 0 0;
`
const Slide = styled.img`
  width: 100%;
  height: 205px;
`

class MainCarousel extends Component {
  responsive = {
    0: { items: 1 },
    600: { items: 2 },
    1024: { items: 3 },
  }
  onSlideChange(e) {}

  onSlideChanged(e) {}

  galleryItems() {
    return [
      { key: 1, img: cover1, text: "Discover The Freedom In Horse Riding" },
      { key: 2, img: cover2, text: "Explore The East Coast of Barbados" },
      { key: 3, img: cover3, text: "Yoga - Pause, Breathe, Relax" },
    ].map((item, i) => (
      <SlideContainer key={`key-${item.key}`}>
        <img style={{ width: "100%" }} src={item.img} />
        <SlideInfo>
          <SlideCallout>{item.text}</SlideCallout>
        </SlideInfo>
      </SlideContainer>
    ))
  }

  render() {
    const items = this.galleryItems()

    return (
      <Container id="home">
        {/* <SlideContainer>
                    <Carousel 
                        showArrows={true}
                        autoPlay
                        infiniteLoop
                        interval={3000}
                        showStatus={false}
                        swipeable
                        showThumbs={false}
                        showIndicators={false}
                        dynamicHeight
                        className="myCarousel"

                        >
                    <div className="caroDiv">
                        <img src={cover1} />
                    </div>
                    <div className="caroDiv">
                        <img src={cover2} />
                    </div>
                    <div className="caroDiv">
                        <img src={cover4} />
                    </div>
                </Carousel>

                </SlideContainer> */}
        <AliceCarousel
          items={items}
          duration={3000}
          autoPlay={true}
          startIndex={0}
          dotsDisabled={false}
          stopAutoPlayOnHover={false}
          buttonsDisabled={true}
          fadeOutAnimation={true}
          mouseDragEnabled={false}
          playButtonEnabled={false}
          autoPlayInterval={3000}
          autoPlayDirection="ltr"
          disableAutoPlayOnAction={false}
          swipeDisabled={false}
          onSlideChange={this.onSlideChange}
          onSlideChanged={this.onSlideChanged}
        />
      </Container>
    )
  }
}

export default MainCarousel

import React, { Component } from 'react';
import styled from 'styled-components'
import './mobile-nav.css'
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll'


const MenuItem = styled.li`
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    display: block;
    color: black;
    transition: 0.3s;
    font-family: 'Quicksand', sans-serif;
	position: relative;
	text-decoration: none;
	line-height: 2.5;
	width: 100%;
    -webkit-tap-highlight-color: transparent;
    
    border-bottom: 1px solid rgba(177, 177, 177, 0.3);
	margin-top: 5px;
	transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
	transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99), opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99), -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);

    :hover {
        /* background-color: #F8A02F; */
    }
`

class MobileNavigation extends Component {
    handleHeaderClick() {
        var header = document.querySelector('.header');
        var icon = document.querySelector('.icon-container');
        icon.onclick = function () {
            // header.classList.toggle('menu-open');
        }
        header.classList.toggle('menu-open');

    }
    render() {
        return (
            <div className="container">

                <div className="header" onClick={this.handleHeaderClick}>
                    <div className="icon-container">
                        <div id="menuicon">
                            <div className="bar bar1"></div>
                            <div className="bar bar2"></div>
                        </div>
                    </div>
                    <div className="mobile-menu">
                        <AnchorLink href="#home"><img src={require('../images/footerLogo.png')} /></AnchorLink>
                        <ul className="menu">
                        {/* <AnchorLink style={{color: 'black', textDecoration: 'none'}} href="#home"><MenuItem>Home</MenuItem></AnchorLink> */}
                        <AnchorLink style={{color: 'black', textDecoration: 'none'}} href="#rides"><MenuItem>Rides</MenuItem></AnchorLink>
                        <AnchorLink style={{color: 'black', textDecoration: 'none'}} offset="90" href="#yoga"><MenuItem>Yoga</MenuItem></AnchorLink>
                        <AnchorLink style={{color: 'black', textDecoration: 'none'}} offset="55" href="#events"><MenuItem>Events</MenuItem></AnchorLink>
                        <AnchorLink style={{color: 'black', textDecoration: 'none'}} href="#hikes"><MenuItem>Hikes</MenuItem></AnchorLink>
                        <AnchorLink style={{color: 'black', textDecoration: 'none'}} href="#adventure"><MenuItem>Adventure Stay</MenuItem></AnchorLink>
                        <AnchorLink style={{color: 'black', textDecoration: 'none'}} offset="15" href="#gallery"><MenuItem>Gallery</MenuItem></AnchorLink>
                        {/* <AnchorLink style={{color: 'black', textDecoration: 'none'}} href="#about"><MenuItem>About</MenuItem></AnchorLink> */}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileNavigation;
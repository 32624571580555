import React, { Component } from 'react';
import styled from 'styled-components'
import YogaGallery from './yoga-gallery';
import Fade from 'react-reveal/Fade'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons'

const Container = styled.div``

const Header = styled.h1`
margin: 50px 0;
text-align: center;
`

const Copy = styled.p`
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    padding: 20px 8%;
    line-height: 200%;
    font-style: ${props=>props.italic ? "italic": 'none'};
    text-align: ${props=>props.center ? "center" : "left"};
    font-weight: ${props=>props.bold ? 600 : 400};
    max-width: ${props=>props.bold ? '800px' : '1000px'};
    margin: 0 auto;
    margin-bottom: 10px;
`

const Subtitle = styled.h1`
    text-align: center;
    font-family: 'PTSerif';
    font-style: italic;
    font-size: ${props=>props.small ? '20px' : '28px'};
    margin-top: 50px;
`

const YogaList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 20px auto;
    text-align: center;
    justify-content: center;
    max-width: 800px;

`
const ListItem = styled.li`
    margin: 10px 10px;
    padding: 10px 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.15);
    transition: 0.6s ease;
    font-family: "Open Sans";
    font-weight: 100;
    font-size: 18px;


    :hover {
        background-color: white;
        color: #f8a02f;
    }

    ::after {
        content: "•";
        color: #f8a02f;
        padding: 0 5px;
    }
`
const PricingContainer = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: center;
    max-width: 900px;
`
const PriceBlock = styled.div`
    border: 1px solid #ddd;
    text-align: center;
    padding: 20px;
    margin: 10px 20px 50px 20px;
    flex: 1;
    border-top: 10px solid #f8a02f;
    border-radius: 10px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
`

const Title = styled.h1`
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-size: 19px;
`
const Price = styled.h2`
    font-weight: 300;
    font-size: 35px;
    color: #f8a02f;
    margin: 0 0 10px 0;
    font-family: "PT Serif";
`

const Pricing = styled.div`
    padding: 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

const PricingCopy = styled.p`
    font-size: 13px;
    width: 250px;
`

const Rate = styled.p`
    text-transform: uppercase;
    font-weight: 100;
    font-family: "Open Sans";
    font-size: 12px;
    color: #241c15;
    letter-spacing: .03125rem;
`

const BookYoga = styled.a`
    background-color: #FFBE6E;
    color: white;
    padding: 10px 15px;
    font-family: 'Open Sans';
    font-weight: 900;
    border: none;
    transition: 0.5s ease;
    font-size: 15px;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    box-shadow: 1px 3px 5px #ffbe6e52;

    :hover {
        background-color: #FFC987;
    }

    :focus {
        background-color: #FFC987;
        outline: none;
    }
`

class Yoga extends Component {
    state = {  }
    render() { 
        return (
            <Container id="yoga">
                <Header>Yoga</Header>
                {/* <Gallery photos={yogaPics} /> */}
                <YogaGallery />
                <Fade>
                    <Copy center>Ocean View Yoga Retreat welcomes you to enjoy a relaxing, restorative yoga session in our beautiful Balinese styled yoga shala.  We are positioned on a cliff enjoying the cleansing and energizing Atlantic Ocean breezes.  Inspiring sea and hillside views whilst hearing sounds of waves and birds allows you to relax and reconnect with yourself as we do a gentle, meditative hour of self love.  Come and escape with Delia Maria or Sheena experienced and qualified yoga teachers.</Copy>
                </Fade>
                <Subtitle>We Offer:</Subtitle>
                <YogaList>
                    <ListItem>1 hr restorative session</ListItem>
                    <ListItem>Grounding</ListItem>
                    <ListItem>Yoga Retreat</ListItem>
                    <ListItem>Full Moon Session</ListItem>
                    <ListItem>Retreat Day/Corporate Day</ListItem>
                    <ListItem>Themed Workshops</ListItem>
                </YogaList>
                <hr style={{opacity: 0.4}}/>
                <Fade left>
                    <FontAwesomeIcon style={{margin: '0 auto', display: 'block', textAlign: 'center'}} size={'1x'} color="#f8a02f" icon={faQuoteLeft} />
                    <Copy italic center bold>"Delia Maria has been practicing Hatha yoga and meditation for 30 years and focuses on self care healing and transformation with relaxing, meditative styled yoga.  Bringing balance to mind, body and spirit through breath and asanas give tools to carry away from your experience here with us at Ocean View Yoga Retreat."</Copy>
                </Fade>
                <Fade right>
                    <Copy italic center bold>"Sheena is a 200hr Hatha Vinyasa Yoga Teacher currently enrolled in an advance teacher training with Yoga Medicine. Sharing the gift of yoga with her students is her passion. She uses the application of yogic postures and knowledge of anatomy to assist those with various medical conditions."</Copy>                
                    <FontAwesomeIcon style={{margin: '15px auto', display: 'block', textAlign: 'center'}} size={'1x'} color="#f8a02f" icon={faQuoteRight} />
                </Fade>
                <hr />
                <Fade>
                    <Pricing>
                        <div style={{margin: ' 0 10px 40px 10px'}}>
                            <Title>Private</Title>
                            <PricingCopy>Sessions are open to all skill levels, including beginners.</PricingCopy>
                            <Price>$50.00</Price>
                            <Rate>PER HOUR</Rate>
                            <BookYoga href="mailto:oceanviewyogaretreat@gmail.com?subject=Booking Request for Yoga&body=Hi there, I'm interested in making a booking for a private yoga session.">Book Now</BookYoga>
                        </div>
                        <div style={{margin: ' 0 10px 40px 10px'}}>
                            <Title>Group Sessions</Title>
                            <PricingCopy>This session is open to groups of 3 or more.</PricingCopy>
                            <Price>$30.00</Price>
                            <Rate>PER HOUR, per person</Rate>
                            <BookYoga href="mailto:oceanviewyogaretreat@gmail.com?subject=Booking Request for Yoga&body=Hi there, I'm interested in making a booking for a group yoga session.">Book Now</BookYoga>
                        </div>
                    </Pricing>
                </Fade>
                <hr />

            </Container>
        );
    }
}

export default Yoga;
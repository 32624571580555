import React, { Component } from 'react';
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import horse from '../images/HorseBdos.png'
import logo from '../images/oceanechologo.png'

const IntroContainer = styled.div`
    padding: 50px 7%;
    
`

const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-columns: 60% auto; */
    overflow: hidden;

    @media (min-width: 950px) {
        grid-template-columns: 60% auto;
    }
`

const TextContainer = styled.div`
    width: 100%;
`
const Title = styled.h1`
    color: #363636;
    font-size: 40px;
    line-height: 45px;
`
const Subtitle = styled.p`
    font-size: 18px;
`
const HorseImage = styled.img`
    max-width: 250px;
    width: 90%;
    /* padding: 0 0 0 40px; */
    margin: 0 auto;
    float: right;
`
const Accent = styled.span`
    color: #F8A02F;
`

class Intro extends Component {
    state = {}
    render() {
        return (
            <IntroContainer>
                <InnerContainer>
                    <Fade bottom>
                        <TextContainer>
                            <Title>You will find <Accent>Ocean Echo Stables</Accent> on the east coast of Barbados.  </Title>
                            <Subtitle>We offer a range of activities to reconnect, celebrate nature and experience a unique slice of island life.</Subtitle>
                        </TextContainer>
                    </Fade>
                    <HorseImage src={logo} />
                </InnerContainer>
            </IntroContainer>
        );
    }
}

export default Intro;
import React, { Component } from 'react';
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import logo from '../images/navLogo.png'

const NavMain = styled.nav`
    background-color: ${props=> props.isScrolled ? 'rgba(0,0,0,0.41)' : null};
    padding: ${props=> props.isScrolled ? '4px 25px' : '7px 25px'};
    display: flex;
    flex-direction: row;
    max-height: 90px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: 0.4s ease;

    @media (max-width: 600px) {
        display: none;
    }
`

const Nav = styled.nav`
    padding: 5% 5%;
    mix-blend-mode: overlay;
    display: flex;
    flex-direction: row;
    max-height: 90px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
`

const Title = styled.h1`
    color: white;
    opacity: 1;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    font-style: italic;
    margin: 0;
    flex: 1;
    mix-blend-mode: overlay;
`

const NavLogo = styled.img`
    width: 40px;
    height: 60px;
    max-width: 40px;
    margin: 0;

    @media (max-width: 400px) {
        width: 25px;
        height: 37px;
        max-width: 25px;
    }
`

const MenuContainer = styled.div`
    color: white;
    font-family: 'Quicksand';
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    flex: 1;
    max-width: 1000px;
    margin: 0 auto;
    

    @media (max-width: 650px) {
        font-size: 12px;
    }

    @media (max-width: 450px) {
        /* display: none;  */
    }
`
const MenuLinks = styled.ul`
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    justify-content: flex-end;
`
const MenuItem = styled.li`
    margin: 0 0 0 30px;
    cursor: pointer;
    list-style: none;
    transition: 0.3s ease;

    :hover {
        color: #F8A02F;
    }

`

class Navbar extends Component {

    state = {
        stuck: false,
        isScrolled: false
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', this.handleScroll)
        }

    }

    handleScroll = (event) => {
        const scrollTop = window.pageYOffset
        const { stuck } = this.state
        // console.log(scrollTop)

        if (scrollTop > 65) {
            if (stuck) {
                //Do NOthing, return
                return
            }
            this.setState({ isScrolled: true, stuck: true })
            // this.props.handleSticky(true)
        } else {
            this.setState({ isScrolled: false, stuck: false })
            // this.props.handleSticky(false)

        }
    }
    
    render() {
        return (
            <NavMain isScrolled={this.state.isScrolled}>
                <AnchorLink style={{color: 'inherit', textDecoration: 'none'}} href="#home"><NavLogo src={logo} /></AnchorLink>
                <MenuContainer>
                    <MenuLinks>
                        {/* <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} href="#home">Home</AnchorLink></MenuItem> */}
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} href="#rides">Rides</AnchorLink></MenuItem>
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} offset="90" href="#yoga">Yoga</AnchorLink></MenuItem>
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} offset="90" href="#events">Events</AnchorLink></MenuItem>
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} href="#hikes">Hikes</AnchorLink></MenuItem>
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} href="#adventure">Adventure Stay</AnchorLink></MenuItem>
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} offset="50" href="#gallery">Gallery</AnchorLink></MenuItem>
                        <MenuItem><AnchorLink style={{color: 'inherit', textDecoration: 'none'}} offset="50" href="#about">About</AnchorLink></MenuItem>
                    </MenuLinks>
                </MenuContainer>
            </NavMain>
        );
    }
}

export default Navbar;
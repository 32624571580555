import React, { Component } from "react"
import styled from "styled-components"

const Container = styled.div``
const Gallery = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
`
const ImageContainer = styled.div`
  flex-basis: 33.33%;
  overflow: hidden;
  /* height: 100%; */
`
const Image = styled.img`
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
`

class YogaGallery extends React.PureComponent {
  render() {
    return (
      <Container>
        <Gallery>
          <ImageContainer>
            <Image src={require("../images/yoga/1-min.jpg")} />
          </ImageContainer>
          <ImageContainer>
            <Image src={require("../images/yoga/2-min.jpg")} />
          </ImageContainer>
          <ImageContainer>
            <Image src={require("../images/yoga/3.jpeg")} />
          </ImageContainer>
        </Gallery>
      </Container>
    )
  }
}

export default YogaGallery

import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components'
import { FaArrowDown } from 'react-icons/fa'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Fade from 'react-reveal/Fade'

// import cover from '../images/apt/1_aptb-min.jpg'
import cover from '../images/adventure-BG.jpg'


const AdventureContainer = styled.div`
    background-image: url(${cover});
    background-repeat: no-repeat;
    background-size: cover; 

    /* Set a specific height */
    /* min-height: 500px;  */

    /* Create the parallax scrolling effect */
    /* background-attachment: fixed;
    background-position: right;*/

    padding: 70px 30px;
    text-align: center;
`
const InfoContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
`
const Title = styled.h1`
    color: #F8A02F;
    font-weight: 700;
    text-shadow: 0 0 5px rgba(0,0,0,0.3);
    font-family: "PT Serif";

`
const Description = styled.p`
    font-size: 16px;
    color: white;
    margin: 0;
    line-height: 200%;
    font-family: "Open Sans";
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
`
const upDown = keyframes`
    from {
        transform: translateY(-20px)
    }

    to {
        transform: translateY(0px)
    }
`
const IconHolder = styled.div`
    margin-top: 25px;
    margin-bottom: 0;
    animation: ${upDown} 1.5s ease-in-out alternate-reverse infinite;

`

class AdventureStay extends Component {
    state = {  }
    render() { 
        return (
            <AdventureContainer id="adventure">
                <InfoContainer>
                    <Title>Adventure Stay</Title>
                    <Fade big>
                        <Description>Come and unwind in the tranquility and beauty of our tropical paradise getaway. Nestled along the rustic east coast with awesome views and cool Atlantic breezes, we offer a few self catering apartment options for those who prefer the ‘off the beaten track’ experience. Your fun farm stay is located very close to Martins Bay a friendly fishing village with horse riding, yoga, hiking and fishing opportunities. <br />Take a look at our apartments below.</Description>
                    </Fade>
<IconHolder>
    <AnchorLink style={{color: 'inherit', textDecoration: 'none'}} offset="50" href="#listings"><FaArrowDown size={30} style={{color: 'white', margin: '5px auto', display: 'block', cursor:'pointer'}} /></AnchorLink>
                </IconHolder>
                </InfoContainer>
            </AdventureContainer>
        );
    }
}
 
export default AdventureStay;
import React, { Component } from 'react';
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import bernard from '../images/people/bernard.jpg'
import delia from '../images/people/Delia.jpg'
import samantha from '../images/people/samantha.jpg'

const Container = styled.div`
    background-color: #F8A02F;
    padding: 10% 5%;
    text-align: center;
    color: white;
    width: 100%;
`
const ScrollContainer = styled.div`
    scroll-snap-type: x mandatory;
    display: contents;
`
// const Heading = styled.p`
//     text-transform: uppercase;
//     font-size: 12px;
//     font-weight: 900;
//     font-family: Helvetica, sans-serif;
//     scroll-snap-align: start;
//     margin: 0 5px 20px 5px;
//     display: inline-block;
//     background-color: white;
//     padding: 5px 15px;
//     border-radius: 5px;
//     color:  #F8A02F;
// `
const Heading = styled.p`
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 900;
    font-family: "Open Sans", sans-serif;
    margin: 0 5px 20px 5px;
    display: ${props=>props.hide ? 'none' : 'inline-block'} ;
    padding: 5px 15px;
    border-radius: 5px;
    color:  white;
    cursor: pointer;

    ::after {
        content: '';
        background-color: white;
        width: 70%;
        height: 2px;
        display: ${props=>props.active ? 'block' : 'none'};
        margin: 0 auto;
    }
`
const Header = styled.h1`
    text-transform: uppercase;
    font-size: 1rem;
    font-family: 'Open Sans';
`
const Quote = styled.h1`
    font-style: italic;
    display: block;
    font-weight: 700;
    font-size: 21px;
    line-height: 39px;
    scroll-snap-align: center;
    margin: 0;
    transition: 1s ease;
    font-family: "PT Serif";
`

const QuoteContainer = styled.div`
    padding: 0 40px;
`

const ProfileImage = styled.img`
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
`

class About extends Component {
    constructor(props){
        super(props)

        this.state = {
            currentQuote: quotes[0]
        }
        this.handleHeadingClick = this.handleHeadingClick.bind(this)
    }

    handleHeadingClick = (quote) => {
        this.setState({currentQuote: quote})
    }

    render() { 
        return (
            <Container id="about">
                <Header>Meet the team:</Header>
            <div>
                {
                    quotes.map((quote)=>(
                        <Heading key={quote.id} active={quote === this.state.currentQuote} hide={!quote.heading} onClick={()=> this.handleHeadingClick(quote)}>{quote.heading}</Heading>
                    ))
                }
            </div>
                <AboutQuote heading={this.state.currentQuote.heading} quote={this.state.currentQuote.copy} image={this.state.currentQuote.image}/>
                {/* <ScrollContainer>
                    <AboutQuote heading="Who we are:" quote="Ocean Echo Stables is a small family run operation established in 2004 offering a range of outdoor activities for riders, nature loving hikers, yoga enthusiasts and adventurous island explorers."/>
                    <AboutQuote heading="Samantha:" quote="Some of my earliest childhood memories include growing up on the family farm the youngest of four children, my Irish mum teaching me how to ride on Queenie. Once i got the hang of it, I was off. In the good company of the  ponies and horses that graced the farm over the years I spent countless hours in the saddle covering miles of sugar caned cart-roads and trails enjoying the freedom of the open country and the beauty of the majestic horse and nature."/>
                    <AboutQuote heading="Bernard:" quote="Born in Dominica the nature isle of the Caribbean I also came to Barbados at an early age. My passions embrace culture and nature and I am a spoken word poet, artist, vegan food enthusiast/chef and the heart and soul of Culture Yard, the newest offering of Ocean Echo Stables.  Along with my queen Sam and our three children, it’s been a rewarding and interesting ride since 2004 and throughout the years with team Ocean Echo Stables."/>
                    <AboutQuote heading="Delia:" quote="My name is Delia, I am Samantha’s sister and I offer the yoga experience of the family business at Ocean View Yoga Retreat. Like Sam, I am a British born Barbadian living here since I was 10 years old. I love country life and farming. I have a passion for yoga and have a very active lifetyle which includes gardening, walks in nature and beach trips. I love animals, cooking local dishes, reading and enjoying music. Yoga has been a part of my life for 30 years now and I am always keen to share the benefits of a yoga session."/>
                </ScrollContainer> */}
            </Container>
        );
    }
}

class AboutQuote extends Component {
    render() {
        return (
            <QuoteContainer>
                <Fade>
                    {/* <Heading>{this.props.heading}</Heading> */}
                    <ProfileImage src={this.props.image} />
                    <Quote>{this.props.quote}</Quote>
                </Fade>
            </QuoteContainer>
        )
    }
}
const quotes = [
    {   
        id: 1,
        heading: "Samantha",
        copy: '"Some of my earliest childhood memories include growing up on the family farm the youngest of four children, my Irish mum teaching me how to ride on Queenie. Once i got the hang of it, I was off. In the good company of the  ponies and horses that graced the farm over the years I spent countless hours in the saddle covering miles of sugar caned cart-roads and trails enjoying the freedom of the open country and the beauty of the majestic horse and nature."',
        image: samantha
    },
    {
        id: 2,
        heading: "Bernard",
        copy: '"Born in Dominica the nature isle of the Caribbean I also came to Barbados at an early age. My passions embrace culture and nature and I am a spoken word poet, artist, vegan food enthusiast/chef and the heart and soul of Culture Yard, the newest offering of Ocean Echo Stables.  Along with my queen Sam and our three children, it’s been a rewarding and interesting ride since 2004 and throughout the years with team Ocean Echo Stables."',
        image: bernard
    },
    {
        id: 3,
        heading: "Delia",
        copy: '"My name is Delia, I am Samantha’s sister and I offer the yoga experience of the family business at Ocean View Yoga Retreat. Like Sam, I am a British born Barbadian living here since I was 10 years old. I love country life and farming. I have a passion for yoga and have a very active lifetyle which includes gardening, walks in nature and beach trips. I love animals, cooking local dishes, reading and enjoying music. Yoga has been a part of my life for 30 years now and I am always keen to share the benefits of a yoga session."',
        image: delia
    }
]
export default About;
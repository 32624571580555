import React, { Component } from 'react';
import styled from 'styled-components'

const Container = styled.div`
    background-color: #FFF7ED;
    text-align: center;
    padding: 50px;
`
const Title = styled.h1`
    color: #2986B3;
`
const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
const CTAButton = styled.a`
    background-color: #FFBE6E;
    color: white;
    padding: 15px 10px;
    font-family: 'PT Serif';
    font-weight: 900;
    border: none;
    margin: 5px;   
    transition: 0.5s ease;
    font-size: 19px;
    border-radius: 5px;
    text-transform: capitalize;
    text-decoration: none;
    width:250px;

    :hover {
        background-color: #FFC987;
    }

    :focus {
        background-color: #FFC987;
        outline: none;
    }
`

class Contact extends Component {
    state = {  }
    render() { 
        return (
            <Container id="contact">
                <Title>Contact us today to book your visit!</Title>
                <ContactContainer>
                    <CTAButton href="tel:1-246-433-6772">Call us now</CTAButton>
                    <CTAButton href="mailto:oceanechostables@hotmail.com?subject=Booking Request">Send an email</CTAButton>
                </ContactContainer>
            </Container>
        );
    }
}
 
export default Contact;
import React, { Component } from 'react';
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import hike from '../images/hike-min.jpg'

const Header = styled.h1`
    margin: 50px 0;
    text-align: center;
`
const Title = styled.h1`
    font-size: 22px;
`

const Container = styled.div`
    padding: 20px 0;
`
const InnerContainer = styled.div`
    padding: 0 5%;
    max-width: 1000px;
    margin: 0 auto;

`
const HikeImg = styled.img`
    object-fit: cover;
    height: 20rem;
    border-radius: 7px;
    width: 100%;
`
const Description = styled.p`
    font-size: 14px;
    line-height: 28px;
`

class Hikes extends Component {
    state = {  }
    render() { 
        return (
            <Container id="hikes">
                <Header>Our Hikes</Header>
                <InnerContainer>
                    <Fade><HikeImg src={hike} /></Fade>
                    <Fade left>
                    <Title>Old Railway Hike:  2 hrs. USD$50/person</Title>
                    <Description>A scenic hike through the countryside to Martins Bay and then along the old railway line to Bath Beach. This is an easy to moderate hike with awesome photo opportunities along the trek that takes you to a part of Barbados that is rarely seen. Experience another side of Barbados in a unique way with this hike. Enjoy some refreshing coconut water at the end.<br />(Enclosed shoes, sneakers, swimwear just in case)</Description>
                    </Fade>
                </InnerContainer>
            </Container>
        );
    }
}
 
export default Hikes;
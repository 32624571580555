import React, { Component } from "react"
import styled from "styled-components"

const CardContainer = styled.div`
  padding: 10% 6% 10% 6%;
  border-radius: 4px;
  box-shadow: 2px 2px 8px rgba(144, 144, 144, 0.4);
  max-width: 560px;
  background-color: white;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  transition: 0.3s ease-in-out;

  :hover {
    box-shadow: 0px 4px 16px rgba(144, 144, 144, 0.6);
  }
`
const RideName = styled.h1`
  color: #363636;
  font-weight: 700;
  font-size: 20px;

  @media (min-width: 600px) {
    font-size: 30px;
  }
`
const RidePrice = styled.h1`
  color: #f8a02f;
  font-size: 30px;
  font-weight: 700;
`
const RideTime = styled.h1`
  color: #b0b0b0;
  font-style: italic;
  font-size: 20px;

  ::after {
    content: "";
    background-color: rgba(0, 0, 0, 0.08);
    width: 40%;
    margin: 20px auto;
    height: 1px;
    display: block;
  }
`
const RideDescription = styled.p`
  font-size: 14px;
  line-height: 23px;
`

const BookRide = styled.a`
  background-color: #ffbe6e;
  color: white;
  padding: 10px 15px;
  font-family: "Quicksand";
  text-transform: uppercase;
  font-weight: 900;
  border: none;
  margin: 5px;
  margin-top: auto;
  transition: 0.5s ease;
  font-size: 15px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 1px 3px 5px #ffbe6e52;
  display: block;

  :hover {
    background-color: #ffc987;
  }

  :focus {
    background-color: #ffc987;
    outline: none;
  }
`

class RideCard extends Component {
  render() {
    const mailToString = `mailto:oceanechostables@hotmail.com?subject=Booking Request for ${this.props.name}&body=Hi there, I'm interested in making a booking for this ride!`
    return (
      <CardContainer>
        <RideName>{this.props.name}</RideName>
        <RidePrice>{this.props.price}</RidePrice>
        <RideTime>{this.props.time}</RideTime>
        <RideDescription>{this.props.description}</RideDescription>
        {this.props.name === "Roots & Rhythm Combo" && (
          <RideDescription>
            <b>3 activities offered:</b>
            <br /> - Beach and countryside horseback trail ride
            <br />
            - Interactive hike with knowledgeable guide or <br />- Restorative
            yoga session with Delia
          </RideDescription>
        )}
        {this.props.pricing && (
          <RideDescription style={{ lineHeight: 1.4, fontWeight: 600 }}>
            {this.props.pricing}
          </RideDescription>
        )}
        <BookRide href={mailToString}>Book Now</BookRide>
      </CardContainer>
    )
  }
}

export default RideCard

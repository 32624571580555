import React, { Component } from "react"
import styled from "styled-components"
import RideCard from "./ride-card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHorseHead } from "@fortawesome/free-solid-svg-icons"

import Fade from "react-reveal/Fade"

import horseshoe from "../images/horseshoe.png"

const RidesContainer = styled.div`
  background-color: #fff7ed;
  padding: 50px 7%;
`
const InnerContainer = styled.div`
  text-align: center;
`

const HorseshoeIcon = styled.img`
  margin: 0;
  padding: 0;
  width: 3rem;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
`

const RideCards = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 900px;
  grid-gap: 20px;
  margin: 0 auto;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Header = styled.h1`
  margin: 20px 0 10px 0;
  font-size: 1.6rem;
`

const Subheader = styled.p`
  font-family: "PTSerif";
  font-style: italic;
  margin: 10px 0 50px 0;
  font-size: 0.9rem;
`

const Subheading = styled.h1`
  margin: 40px;
  font-size: 30px;
  font-weight: 700;
  font-style: italic;

  ::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.08);
    width: 80%;
    margin: 20px auto;
    height: 1px;
    display: block;
  }
`
const List = styled.ul`
  font-family: "PT Serif";
  list-style-position: inside;
  font-size: 16px;
  text-align: center;
  margin: 0;
`
const ListItem = styled.li``

class Rides extends Component {
  render() {
    return (
      <RidesContainer id="rides">
        <InnerContainer>
          <HorseshoeIcon src={horseshoe} />
          <Header>Daily Trail Rides</Header>
          <Subheader>A quick view of the rides we offer</Subheader>
          <RideCards>
            {rides.map(ride => (
              <RideCard
                key={ride.name}
                name={ride.name}
                price={ride.price}
                time={ride.time}
                description={ride.description}
                pricing={ride.pricing ?? null}
              />
            ))}
          </RideCards>
          <Subheading>Riding Details</Subheading>
          <Fade bottom>
            <List>
              <ListItem>Cash only payment - USD / BDS accepted</ListItem>
              <ListItem>
                We conduct tours Monday to Saturday, 8.30am or 3.00pm
              </ListItem>
              <ListItem>
                Recommended attire: Long pants & enclosed footwear. (We provide
                safety helmets)
              </ListItem>
              <ListItem>Our rides are limited to six riding guests.</ListItem>
              <ListItem>
                Weight limit of 210lbs (95kg / 15 stone) in effect
              </ListItem>
              <ListItem>Age restriction: 10 yrs and over welcomed</ListItem>
              <ListItem>
                Riders must be of moderate to good fitness levels.
              </ListItem>
              <ListItem>
                Private: for an extra USD$20/person we can make your ride
                exclusive
              </ListItem>
            </List>
          </Fade>
        </InnerContainer>
      </RidesContainer>
    )
  }
}

const rides = [
  {
    name: "Coastal Countryside Ride",
    price: "USD$70",
    time: "1hr",
    description:
      "Enjoy incredible views from your horseback, riding fun trails in the coastal countryside passing banana groves on our way down to Bath beach to take a few pics.",
  },
  {
    name: "Bath Beach Riding Tour",
    price: "USD$80",
    time: "1.5hrs",
    description:
      "This is a great tour for the beginner equestrian who is looking for a little adventurous activity. Riders are likely to spot some mongoose or green monkeys along the way as we trek through lush vegetation on down to Bath Beach where we ride along the sand.",
  },
  {
    name: "Off The Beaten Track",
    price: "USD$90",
    time: "2hrs",
    description:
      "Our horses take you to some of our favourite look-out points affording spectacular views of the beautiful east coast of our island. Experience the wilderness of the unspoiled Atlantic coastline taking in Bath Beach and the stunning Congor Bay.",
  },
  {
    name: "Conset Bay Trail Ride",
    price: "USD$140",
    time: "2.5-3hrs",
    description:
      "For more experienced riders who love a challenging ride and are happy on horseback for longer periods of time at varied paces, this is the ride for you.  Mounted on fine endurance horses, there’s plenty to see as you take in incredible views along the trek.  Visit Bath Beach, Codrington College along the way as we meander through cart roads on down to Conset Bay, a fishing village nestled deep in the east coast of the countryside of Barbados.",
  },
  {
    name: "Full Moon Ride",
    price: "USD$125",
    time: "1.5 hrs",
    description:
      "For a truly magical ride nothing beats riding in the full moon at a leisurely pace. This cool night ride is for the adventurous looking for a unique and memorable riding experience in those bright Caribbean nights around full moon. Prepare for an unparalleled experience of Caribbean wildlife at night, meet fire flies and fruit bats (Riding experience necessary).",
  },
  // {
  //   name: "Rider Picnic",
  //   price: "USD$150",
  //   time: "3hrs",
  //   description:
  //     "Ride down to Bath Beach to meet a tasty picnic prepared specially for you. Bring along your swimwear for a quick dip or explore the beach a bit by foot. Or you can relax with a bottle of wine and some local cuisine when you return to the stables from your ride – the option is yours!",
  // },
  {
    name: "Roots & Rhythm Combo",
    price: "Choose one activity",
    time: "3hrs",
    description:
      "Enjoy your morning or afternoon relaxing at our tucked away countryside sanctuary along the east coast. Experience one of our activities followed by a tasty Caribbean ital meal (vegetarian fare) served alongside homemade juices/local wines (all farm to table)",
    pricing:
      "USD$110/person (trail ride) | USD$80/person (yoga) | USD$70/person (hike)",
  },
]
export default Rides

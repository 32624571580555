import React, { Component } from 'react';
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'

import wedding from '../images/Events/4.jpg'
import stage from '../images/Events/2.jpg'

const Container = styled.div``
const Header = styled.h1`
    margin: 50px 0 10px 0;
    text-align: center;
`
const Subtitle = styled.p`
    text-align: center;
    font-family: 'Quicksand';
    padding: 0 10%;
    font-style: italic;
    line-height: 200%;
    max-width: 1000px;
    margin: 0 auto;

    ::after {
        display: block;
        content: '';
        height: 5px;
        margin: 15px auto;
        width: 10%;
        background-color: #F8A02F;
        border-radius: 3.5px;
    }
`
const GridContainer = styled.div`
    padding: 0 5%;
    margin: 50px 0;

    @media (min-width: 800px) {
        display: grid;
        grid-template-columns: 40% auto;
    }

    ::after {
        display: block;
        content: '';
        height: 5px;
        margin: 0 auto;
        width: 20%;
        background-color: #F8A02F;
        border-radius: 3.5px;

        @media (min-width: 800px) {
            display: none;
        }
    }
`
const Copy = styled.p`
    padding: 0 20px;
    line-height: 200%;
    font-weight: 16px;
    text-align: ${props=> props.right ? 'right' : 'left'};
`
const Wedding = styled.img`
    border-radius: 10px;
`

const BookEvent = styled.a`
    background-color: #FFBE6E;
    color: white;
    padding: 10px 15px;
    font-family: 'Open Sans';
    font-weight: 900;
    border: none;
    margin: 20px 10%;   
    transition: 0.5s ease;
    font-size: 15px;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 1px 3px 5px #ffbe6e52;
    display: block;
    text-align: center;

    :hover {
        background-color: #FFC987;
    }

    :focus {
        background-color: #FFC987;
        outline: none;
    }
`




class Events extends Component {
    state = {  }
    render() { 
        return (
            <Container id="events">
                <Header>Book your event with us</Header>
                <Subtitle>Conveniently located along the main coastal road between Martins Bay and Bath beach, in the eastern parish of St. John we specialize in group hospitality.</Subtitle>

                <GridContainer>
                    <Fade>
                        <Wedding src={wedding}/>
                    </Fade>
                    <Fade bottom>
                        <Copy>Between Ocean Echo Stables and Ocean View Yoga Retreat our facilities offer a rustic, naturally serene environment ideal for weddings, retreats, corporate days, family reunions and workshops.<br/><br/>We promise flexibility in the planning of your special event and can easily incorporate activities such as yoga, trail riding and hiking, if desired, to create unique experiences and cherished memories.</Copy>
                    </Fade>
                </GridContainer>

                <GridContainer>
                    <Fade>
                        <Wedding src={stage}/>
                    </Fade>
                    <Fade bottom>
                        <Copy>We are also home to Culture Yard which frequently hosts varied stage shows, featuring local and regional reggae artists, spoken word performances and DJ sessions complete with blazing bonfire for all to enjoy.<br/><br/>Check out our FireHouse Cafe specializing in tasty nutritious vegetarian/pescatarian options using fresh local ingredients.</Copy>
                    </Fade>
                </GridContainer>
                <BookEvent href="mailto:oceanechostables@hotmail.com?subject=Event Booking Request&body=Hi there, I'm interested in making a booking for an Event at Ocean Echo Stables!">Book an event with us today</BookEvent>
            </Container>
        );
    }
}

export default Events;
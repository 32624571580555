import React, { Component } from "react"
import styled, { keyframes } from "styled-components"
import Gallery from "react-photo-gallery"
import Lightbox from "react-images"

const fadeIn = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`

const Container = styled.div`
  max-width: 900px;
  margin: 40px auto;
  animation: ${fadeIn} 0.5s ease-in-out forwards;
`
const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    /* align-items: center; */
  }
`
const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover;

  @media (min-width: 600px) {
    width: 200px;
    height: 200px;
  }
`
const ContentContainer = styled.div`
  /* margin: 0 0 0 10px; */
`
const Title = styled.h1`
  color: #f8a02f;
  font-size: 2rem;
`
const Description = styled.p`
  font-size: 0.9rem;
  line-height: 170%;
  font-family: "Quicksand";

  /* font-family: 'Roboto'; */
`

const Subtitle = styled.h2`
  margin: 0 0 20px 0;
  font-size: 0.875rem;
  font-weight: 600;
  color: #484848;
  font-family: "Quicksand";
`
const Price = styled.p`
  font-size: 1.1rem;
  color: green;
`

const BookApt = styled.a`
  background-color: #ffbe6e;
  color: white;
  padding: 10px 15px;
  font-family: "Open Sans";
  font-weight: 900;
  border: none;
  margin: 20px 5px;
  transition: 0.5s ease;
  font-size: 15px;
  border-radius: 5px;
  text-decoration: none;
  box-shadow: 1px 3px 5px #ffbe6e52;
  display: block;
  text-align: center;

  :hover {
    background-color: #ffc987;
  }

  :focus {
    background-color: #ffc987;
    outline: none;
  }
`
const GalleryText = styled.p`
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0 0 25px 0;
  color: #484848;
  font-family: "Quicksand" !important;

  ::after {
    content: "";
    height: 2px;
    display: block;
    background-color: #f8a02f;
    width: 5%;
    margin: 4px auto;
  }
`

class ApartmentItem extends Component {
  constructor() {
    super()
    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  render() {
    return (
      <Container>
        <InnerContainer>
          {/* <Image src={this.props.cover} /> */}
          <ContentContainer>
            <Title>{this.props.title}</Title>
            <Subtitle>The space</Subtitle>
            <Description>{this.props.listing}</Description>
            <Price>{this.props.pricing}</Price>
          </ContentContainer>
        </InnerContainer>
        <hr style={{ opacity: 0.3 }} />
        <GalleryText>Apartment Gallery:</GalleryText>
        <Gallery photos={this.props.photos} onClick={this.openLightbox} />
        <Lightbox
          images={this.props.photos}
          onClose={this.closeLightbox}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.currentImage}
          isOpen={this.state.lightboxIsOpen}
        />
        {this.props.irie ? (
          <BookApt href="mailto:oceanechostables@hotmail.com?subject=Booking Request for an Adventure Stay&body=Hi there, I'm interested in making a booking for an Adventure Stay Apartment!">
            Book this Apartment
          </BookApt>
        ) : (
          <BookApt href="mailto:oceanviewyogaretreat@gmail.com?subject=Booking Request for an Adventure Stay&body=Hi there, I'm interested in making a booking for an Adventure Stay Apartment!">
            Book this Apartment
          </BookApt>
        )}
        <hr style={{ opacity: 0.3 }} />
      </Container>
    )
  }
}

export default ApartmentItem

import React, { Component } from "react"
import styled from "styled-components"
import { FaFacebook, FaInstagram, FaEnvelope } from "react-icons/fa"

import logo from "../images/footerLogo.png"

const FooterHold = styled.div`
  background-color: #292929;
  padding: 20px;
  color: whitesmoke;
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
`

const FooterLogo = styled.img`
  width: 80px;
  height: 119px;
  max-width: 80px;
  object-fit: cover;
`
const FooterInfo = styled.div``
const Title = styled.h1`
  font-size: 28px;
`
const Address = styled.h1`
  font-size: 18px;
  font-style: italic;
  line-height: 130%;
`
const Telephone = styled.h1`
  font-size: 18px;
  font-style: italic;
  opacity: 0.76;
  text-decoration: none;
  color: white !important;
`

const Exerpt = styled.p`
  font-size: 14px;
  opacity: 0.76;
`

const SocialsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 150px;
  margin: 10px 0;
`

const SocialIcon = styled.div`
  font-size: 24px;
  display: inline;
  transition: 0.5s ease;

  &:hover {
    color: #f8a02f;
  }
`

class Footer extends Component {
  render() {
    return (
      <FooterHold>
        <FooterContainer>
          <FooterLogo src={logo} />
          <FooterInfo>
            <Title>Ocean Echo Stables</Title>
            <Address>
              New Castle,
              <br />
              St. John,
              <br />
              Barbados
            </Address>
            <SocialsContainer>
              <SocialIcon>
                <a
                  style={{ color: "inherit" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/OceanEchoStables/"
                >
                  <FaFacebook />
                </a>
              </SocialIcon>
              <SocialIcon>
                <a
                  style={{ color: "inherit" }}
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/oceanechostables/"
                >
                  <FaInstagram />
                </a>
              </SocialIcon>
              <SocialIcon>
                <a
                  style={{ color: "inherit" }}
                  rel="noopener noreferrer"
                  target="_top"
                  href="mailto:oceanechostables@hotmail.com"
                >
                  <FaEnvelope />
                </a>
              </SocialIcon>
            </SocialsContainer>
            <Telephone style={{ marginBottom: 4 }}>
              <a
                style={{ color: "inherit", textDecoration: "none" }}
                rel="noopener noreferrer"
                target="_top"
                href="tel:1-246-433-6772"
              >
                1-246-433-6772
              </a>
            </Telephone>
            <Telephone>oceanechostables@hotmail.com</Telephone>
            <Exerpt>
              We offer a range of activities to reconnect, celebrate nature and
              experience a unique slice of island life.
            </Exerpt>
          </FooterInfo>
        </FooterContainer>
      </FooterHold>
    )
  }
}

export default Footer

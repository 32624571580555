import React, { Component } from "react"
import Gallery from "react-photo-gallery"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import Lightbox from "react-images"

import pic1 from "../images/Gallery/7-min.jpg"
import pic2 from "../images/Gallery/8-min.jpg"
import pic3 from "../images/Gallery/3-min.jpg"
import pic4 from "../images/Gallery/9-min.jpg"
import pic5 from "../images/Gallery/10-min.jpg"
import pic6 from "../images/Gallery/11-min.jpg"
import pic7 from "../images/Events/1.jpg"
import pic8 from "../images/Events/3.jpeg"
import pic9 from "../images/Events/2.jpg"

const GalleryContainer = styled.div`
  padding: 10px 20px;
`
const Header = styled.h1`
  margin: 40px 0;
  text-align: center;
`
class GalleryComp extends Component {
  constructor() {
    super()
    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }

  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    })
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }
  render() {
    return (
      <GalleryContainer id="gallery">
        <Header>Our Gallery</Header>
        <Fade>
          <Gallery photos={PHOTOS} onClick={this.openLightbox} />
          <Lightbox
            images={PHOTOS}
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        </Fade>
      </GalleryContainer>
    )
  }
}

const PHOTOS = [
  {
    src: pic1,
    width: 1008,
    height: 756,
  },
  {
    src: pic2,
    width: 960,
    height: 720,
  },
  {
    src: pic3,
    width: 1200,
    height: 800,
  },
  {
    src: pic4,
    width: 2560,
    height: 1709,
  },
  {
    src: pic5,
    width: 1440,
    height: 959,
  },
  {
    src: pic6,
    width: 1440,
    height: 1440,
  },
  {
    src: pic7,
    width: 1080,
    height: 1080,
  },
  {
    src: pic8,
    width: 1280,
    height: 960,
  },
  {
    src: pic9,
    width: 1600,
    height: 901,
  },
]
export default GalleryComp
